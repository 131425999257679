import React, { useCallback, useMemo, useState } from "react";
import { Button } from "@progress/kendo-react-buttons";
import styles from "./tkToolbar.module.scss";
import { Checkbox, Input } from "@progress/kendo-react-inputs";
import { Tooltip } from "@progress/kendo-react-tooltip";
import { Card } from "@progress/kendo-react-layout";
import {
  getAvailableTCActionsToPerform,
  performTCAction,
} from "../PerformTCActions";
import { ETCPerformAction } from "../interfaces";
import { PerformActionToSettings } from "../helpers";
import { buttonThemeColor } from "../../../helpers/interfaces";

export const TimeCardsActions = (props: {
  checkedTCS: number[];
  onToggleCheckAll: () => void;
  enableTCSCount: number;
  clearCheckedIds: () => void;
  refreshList: () => void;
  isCompareMode: boolean;
}) => {
  const {
    checkedTCS,
    enableTCSCount,
    refreshList,
    clearCheckedIds,
    onToggleCheckAll,
    isCompareMode,
  } = props;
  const [comment, setComment] = useState("");
  const availableActions = useMemo(() => getAvailableTCActionsToPerform(), []);

  const refreshAfterAction = useCallback(() => {
    setComment("");
    clearCheckedIds();
    refreshList();
  }, [setComment, refreshList, clearCheckedIds]);

  const tryAction = useCallback(
    (actionName: ETCPerformAction) => {
      performTCAction({
        actionName,
        tcs: checkedTCS,
        comment,
        refreshAfterAction,
      });
    },
    [comment, checkedTCS, refreshAfterAction]
  );
  const isCompactMode = !checkedTCS.length || isCompareMode;
  return (
    <>
      <Tooltip anchorElement="target" showCallout={false} parentTitle={true}>
        <Card
          className={styles.Card}
          style={{
            width: isCompactMode ? 180 : 495,
          }}
          type={"primary"}
        >
          <Checkbox
            value={
              checkedTCS.length === 0
                ? false
                : checkedTCS.length === enableTCSCount
                ? true
                : null
            }
            label={` `}
            onChange={onToggleCheckAll}
            size={"large"}
            disabled={isCompareMode}
          />
          <div className={styles.CheckedNumbers} style={{}}>
            <div title={"Checked"}>{checkedTCS.length}</div>
            <div>of</div>
            <div title={"Available for Actions"}>{enableTCSCount}</div>
          </div>
          <div
            style={
              isCompactMode
                ? { display: "none" }
                : {
                    display: "flex",
                    gap: 4,
                  }
            }
          >
            <Input
              placeholder={"Comment"}
              value={comment}
              onChange={(e) => {
                setComment(e.value);
              }}
              style={{ width: 250 }}
            />
            {availableActions.map((action) => (
              <Button
                key={action}
                {...PerformActionToSettings[action]}
                themeColor={
                  PerformActionToSettings[action].themeColor as buttonThemeColor
                }
                fillMode={"flat"}
                onClick={() => tryAction(action)}
              />
            ))}
          </div>
        </Card>
      </Tooltip>
    </>
  );
};
