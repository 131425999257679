import "./core/tools/CustomPromise"; // Setup custom Promise
import "./inversify.config"; // Setup DI Container
import React from "react";
import ReactDOM from "react-dom/client";
import "hammerjs"; // kendo chart need this dependency.
import {
  ModuleRegistry,
  provideGlobalGridOptions,
  themeQuartz,
} from "ag-grid-community";
import { AllEnterpriseModule, LicenseManager } from "ag-grid-enterprise";
import "@progress/kendo-font-icons/dist/index.css";
import "@mdi/font/css/materialdesignicons.min.css";
import "./assets/icons/foundationIcons/css/foundation-icons.css";
import "./App.scss";
import { App } from "./App";
import "./tools/errorHandlers";

import "./assets/styles/fxaggrid.scss";
import config from "./config";

ModuleRegistry.registerModules([AllEnterpriseModule]);
LicenseManager.setLicenseKey(config.AG_GRID_KEY);
const myTheme = themeQuartz.withParams({
  spacing: 8,
  fontSize: 12,
  oddRowBackgroundColor: "#f5f5f5",
});
provideGlobalGridOptions({
  theme: myTheme,
  grandTotalRow: "bottom",
  pivotPanelShow: "onlyWhenPivoting",
  suppressAggFuncInHeader: true,
  rowGroupPanelShow: "onlyWhenGrouping",
});
const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement
);
root.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>
);
