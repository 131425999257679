import { CustomToolPanelProps } from "ag-grid-react";
import { useCallback, useState } from "react";
import { PropsSQLDBStatusResponse } from "../../../core/api/generated/conterra";
import { Button } from "@progress/kendo-react-buttons";
import { settingsStorage } from "../../../helpers/settings";
import { useRefresher } from "../../../core/tools/Hooks";
import { ColumnState } from "ag-grid-community";
import styles from "../aggrid.module.scss";
import ViewRow from "./ViewItem";

const storageKey = "aggrid-views-statusBD";

function readViews(): IView[] {
  const viewsJSON = settingsStorage.getForCurrentUser(storageKey);
  return viewsJSON ? JSON.parse(viewsJSON) : [];
}

function saveViews(views: IView[]) {
  settingsStorage.setForCurrentUser(storageKey, JSON.stringify(views));
}

interface IView {
  title: string;
  id: string;
  columnStateJSON: string;
  rowGroupColIdsJSON: string;
  pivotMode: boolean | undefined;
}

export interface ViewsToolPanelProps extends CustomToolPanelProps {
  ownerStatsColIds: string[];
  milestonesStatsColIds: string[];
}

export default function ViewsToolPanel(props: ViewsToolPanelProps) {
  const { api: gridApi, ownerStatsColIds, milestonesStatsColIds } = props;
  const [selectedViewId, setSelectedViewId] = useState<string>();

  const pivotByOwner = useCallback(() => {
    setSelectedViewId("pivot");
    gridApi.updateGridOptions({ pivotMode: true });
    gridApi.setValueColumns(ownerStatsColIds);
    gridApi.setRowGroupColumns([PropsSQLDBStatusResponse.owner]);
    gridApi.autoSizeColumns([PropsSQLDBStatusResponse.owner]);
    gridApi.closeToolPanel();
  }, [gridApi, ownerStatsColIds]);

  const pivotByMilestones = useCallback(() => {
    setSelectedViewId("milestones");
    gridApi.resetColumnState();
    gridApi.updateGridOptions({ pivotMode: false });
    gridApi.setColumnsVisible(milestonesStatsColIds, true);
    const restColumns = gridApi
      .getAllGridColumns()
      .filter((x: any) => !milestonesStatsColIds.includes(x.colId));
    gridApi.setColumnsVisible(restColumns, false);
    gridApi.closeToolPanel();
  }, [gridApi, milestonesStatsColIds]);

  const resetState = useCallback(async () => {
    setSelectedViewId(undefined);
    gridApi.resetColumnState();
    gridApi.updateGridOptions({ pivotMode: false });
    await gridApi.setColumnFilterModel(PropsSQLDBStatusResponse.active, {
      filterType: "set",
      values: ["true"],
    });
    gridApi.onFilterChanged();
    gridApi.closeToolPanel();
  }, [gridApi]);

  const refresher = useRefresher();
  const saveView = useCallback(() => {
    const colState = gridApi.getColumnState();
    const rowGroupColIds = gridApi
      .getRowGroupColumns()
      .map((x) => x.getColId());
    const views = readViews();
    views.push({
      id: new Date().valueOf().toString(),
      columnStateJSON: JSON.stringify(colState),
      rowGroupColIdsJSON: JSON.stringify(rowGroupColIds),
      title: `View #${views.length + 2}`,
      pivotMode: gridApi.getGridOption("pivotMode"),
    });
    saveViews(views);
    refresher();
  }, [gridApi, refresher]);
  const deleteView = useCallback(
    (id: string) => {
      const views = readViews();
      saveViews(views.filter((x) => x.id !== id));
      refresher();
    },
    [refresher]
  );
  const renameView = useCallback(
    (id: string, title: string) => {
      const views = readViews();
      saveViews(views.map((x) => (x.id !== id ? x : { ...x, title })));
      refresher();
    },
    [refresher, saveViews, readViews]
  );
  const applyView = useCallback(
    (id: string) => {
      const views = readViews();
      const view = views.find((x) => x.id === id);
      if (!view) {
        alert("View description not found");
        return;
      }
      gridApi.applyColumnState({
        state: JSON.parse(view.columnStateJSON) as ColumnState[],
      });
      gridApi.setRowGroupColumns(JSON.parse(view.rowGroupColIdsJSON));
      gridApi.updateGridOptions({ pivotMode: view.pivotMode });
      gridApi.closeToolPanel();
      setSelectedViewId(id);
    },
    [gridApi]
  );
  const currentViews = readViews();

  return (
    <div style={{ display: "flex", flexDirection: "column", height: "100%" }}>
      <div className={`${styles.Views} ${styles.DefaultViews}`}>
        <Button
          fillMode={"flat"}
          themeColor={!selectedViewId ? "success" : undefined}
          onClick={() => resetState()}
        >
          Default
        </Button>
        <Button
          fillMode={"flat"}
          themeColor={selectedViewId === "pivot" ? "success" : undefined}
          onClick={() => pivotByOwner()}
        >
          Owner Stats
        </Button>
        <Button
          fillMode={"flat"}
          themeColor={selectedViewId === "milestones" ? "success" : undefined}
          onClick={() => pivotByMilestones()}
        >
          Milestones
        </Button>
      </div>
      {currentViews.map((view) => (
        <ViewRow
          key={view.id}
          id={view.id}
          title={view.title}
          deleteView={deleteView}
          isSelected={selectedViewId === view.id}
          apply={applyView}
          edit={renameView}
        />
      ))}
      <div className={styles.Views}>
        <Button
          fillMode={"flat"}
          onClick={() => saveView()}
          iconClass={"mdi mdi-table-plus"}
        >
          Save Current View
        </Button>
      </div>
    </div>
  );
}
