import axios, {
  AxiosHeaders,
  AxiosInstance,
  AxiosRequestConfig,
  HeadersDefaults,
  ResponseType,
} from "axios";
import { Promise as BBPromise } from "bluebird";
import {
  BBPromise as BBPromiseInterface,
  IAuthProvider,
  ILogger,
} from "../interfaces";
import { MobileFile } from "../tools/mobileFile";
import config from "../../config";
import createAuthRefreshInterceptor from "axios-auth-refresh";
import {
  ApiCancellationError,
  ApiExceptionError,
  ApiInformationError,
  ApiNetworkError,
  ApiSyntaxError,
  ApiWarningError,
  transformApiError,
} from "./apiExceptions";

export type QueryParamsType = Record<string | number, any>;

export interface FullRequestParams
  extends Omit<AxiosRequestConfig, "data" | "params" | "url" | "responseType"> {
  /** request path */
  path: string;
  /** content type of request body */
  type?: ContentType;
  /** query params */
  query?: QueryParamsType;
  /** format of response (i.e. response.json() -> format: "json") */
  format?: ResponseType;
  /** request body */
  body?: unknown;
}

export enum ContentType {
  Json = "application/json",
  FormData = "multipart/form-data",
  UrlEncoded = "application/x-www-form-urlencoded",
  Text = "text/plain",
}

export class HttpClient {
  readonly axios: AxiosInstance;

  constructor(authProvider: IAuthProvider, logger: ILogger) {
    this.axios = axios.create({
      baseURL: config.API_URL,
    });

    createAuthRefreshInterceptor(this.axios, authProvider.handleUnauthorized);

    this.axios.interceptors.request.use(async (requestConfig) => {
      let headers = requestConfig.headers as AxiosHeaders;
      let headersToAdd = authProvider.getHeaders();
      for (const [key, value] of Object.entries(headersToAdd)) {
        headers.set(key, value);
      }
      return requestConfig;
    }, Promise.reject);

    this.axios.interceptors.request.use((requestConfig) => {
      logger.verbose(
        "Request {HttpMethod} {HttpUrl} {HttpParams}",
        requestConfig.method,
        requestConfig.url,
        JSON.stringify(requestConfig.params)
      );
      return requestConfig;
    }, Promise.reject);

    this.axios.interceptors.response.use(
      (response) => {
        logger
          .enrich({
            Status: response.status,
            StatusText: response.statusText,
          })
          .verbose(
            "Response {HttpMethod} {HttpUrl} {HttpParams}",
            response.config.method,
            response.config.url,
            JSON.stringify(response.config.params)
          );
        return response;
      },
      (error) => {
        const transformedError = transformApiError(error);
        const messageTemplate =
          " {HttpMethod} {HttpUrl} {HttpCode} {HttpParams}: {ExceptionMessage}";
        const messageParams = [
          error.config?.method,
          error.config?.url,
          error.code,
          error.config?.params ? JSON.stringify(error.config?.params) : "",
          error.message,
        ];
        if (transformedError instanceof ApiCancellationError) {
          logger.verbose(
            "Request Cancelled" + messageTemplate,
            ...messageParams
          );
        } else if (transformedError instanceof ApiNetworkError) {
          logger.verbose(
            "Request Network Error" + messageTemplate,
            ...messageParams
          );
        } else if (transformedError instanceof ApiSyntaxError) {
          logger.fatal(
            "Request Processing Failed" + messageTemplate,
            ...messageParams
          );
        } else if (
          transformedError instanceof ApiInformationError ||
          transformedError instanceof ApiWarningError ||
          transformedError instanceof ApiExceptionError
        ) {
          logger.verbose(
            "Request Expected Error" + messageTemplate,
            ...messageParams
          );
        } else {
          logger.error("Request Error " + messageTemplate, ...messageParams);
        }
        return Promise.reject(transformedError);
      }
    );
  }

  public request = <T>({
    path,
    type,
    query,
    format,
    body,
    ...params
  }: FullRequestParams): BBPromiseInterface<T> => {
    const requestParams = this.mergeRequestParams(params, {});
    const responseFormat = format || undefined;

    if (type === ContentType.FormData && body && typeof body === "object") {
      body = this.createFormData(body as Record<string, unknown>);
    }

    if (type === ContentType.Text && body && typeof body !== "string") {
      body = JSON.stringify(body);
    }

    return new BBPromise((resolve, reject, onCancel) => {
      const abortController = new AbortController();
      onCancel && onCancel(() => abortController.abort());
      this.axios
        .request({
          ...requestParams,
          signal: abortController.signal,
          headers: {
            ...(requestParams.headers || {}),
            ...(type ? { "Content-Type": type } : {}),
          },
          params: query,
          responseType: responseFormat,
          data: body,
          url: path,
        })
        .then((response) => resolve(response.data))
        .catch((error) => reject(error));
    });
  };

  protected mergeRequestParams(
    params1: AxiosRequestConfig,
    params2?: AxiosRequestConfig
  ): AxiosRequestConfig {
    const method = params1.method || (params2 && params2.method);

    return {
      ...this.axios.defaults,
      ...params1,
      ...(params2 || {}),
      headers: {
        ...((method &&
          this.axios.defaults.headers[
            method.toLowerCase() as keyof HeadersDefaults
          ]) ||
          {}),
        ...(params1.headers || {}),
        ...((params2 && params2.headers) || {}),
      },
    };
  }

  protected stringifyFormItem(formItem: unknown) {
    if (typeof formItem === "object" && formItem !== null) {
      return JSON.stringify(formItem);
    } else {
      return `${formItem}`;
    }
  }

  protected createFormData(input: Record<string, unknown>): FormData {
    return Object.keys(input || {}).reduce((formData, key) => {
      const property = input[key];
      const propertyContent: any[] =
        property instanceof Array ? property : [property];

      for (const formItem of propertyContent) {
        const isFileTypeMobile = formItem instanceof MobileFile;
        let isFileType = false;
        // @ts-ignore
        if (typeof File === "function") {
          // @ts-ignore
          isFileType = formItem instanceof Blob || formItem instanceof File;
        }

        if (isFileType) {
          formData.append(key, formItem);
        } else if (isFileTypeMobile) {
          // @ts-ignore
          formData.append(key, formItem.getFileBody());
        } else {
          formData.append(key, this.stringifyFormItem(formItem));
        }
      }

      return formData;
    }, new FormData());
  }
}
