import React from "react";
import DashboardGrid from "../Dashboard/Grid";
import { IColumnSetting, IFilterSetting } from "../Dashboard/interfaces";
import { Button } from "@progress/kendo-react-buttons";
import CardManagement from "../Cards/CardManagement";
import { pageId, simpleObject } from "../../helpers/interfaces";
import OpenCardLink from "../OpenCardLink";
import { RunScriptAsync } from "../../helpers/runscripts";
import { showSomeError } from "../../helpers/helpers";
import BaseComponent from "../BaseComponent";
import { Tooltip } from "@progress/kendo-react-tooltip";
import { Menu, MenuItem, MenuSelectEvent } from "@progress/kendo-react-layout";
import { Popup } from "@progress/kendo-react-popup";
import { ModalRef } from "../Common/Modal/Modal";
import { TextArea } from "@progress/kendo-react-inputs";
import api from "../../core/api/api";

interface IProps {
  buildPlanId: number;
  isActive: boolean;
  pageId: pageId;
  defaultColumnValuesFilter?: { field: string; values: any[] }[];

  onChangeGridData?(data: Array<simpleObject>): void;
}

interface IState {
  contextMenu:
    | {
        position: {
          left: number;
          top: number;
        };
        woId: number;
        comments: string;
      }
    | undefined;
}

const CopyTextComponent = (props: { text: string }) => {
  const { text } = props;

  return (
    <div style={{ display: "flex", gap: 4, maxHeight: 500, overflow: "auto" }}>
      <div style={{ flex: 1, padding: "6px 0" }}>{text}</div>
      <Button
        fillMode={"flat"}
        themeColor={"primary"}
        iconClass={"mdi mdi-content-copy"}
        onClick={async () => {
          try {
            const id = +new Date();
            await navigator.clipboard.writeText(text);
            ModalRef.showNotification({
              text: "Successfully Copied!",
              id,
              style: { fontSize: 12 },
              type: { style: "success" },
            });
            setTimeout(() => {
              ModalRef.hideNotification(id);
            }, 1500);
          } catch (error) {
            showSomeError(error);
          }
        }}
      />
    </div>
  );
};

class WorkOrders extends BaseComponent<IProps, IState> {
  gridRef: any = React.createRef();
  woId: number | undefined = undefined;
  bpId = this.props.buildPlanId;
  isBPTab: boolean = !!this.props.buildPlanId;
  setDefaultFilters: boolean = !this.isBPTab || this.props.pageId === "LiveMap";
  COLUMNS: Array<IColumnSetting>;
  FILTERS: Array<IFilterSetting> = [
    {
      id: "filterPeriod",
      type: "dateRange",
      serverFilter: {
        param: "",
      },
      dateRangeSettings: {
        dateFromParam: "dateFrom",
        dateToParam: "dateTo",
        defaultPeriod: "last12months",
        allowEmptyValues: false,
      },
      toolbarRow: 1,
    },
  ];

  constructor(props: IProps) {
    super(props);
    this.state = {
      contextMenu: undefined,
    };

    this.COLUMNS = [];
    if (!this.isBPTab) {
      this.COLUMNS.push(
        {
          field: "BuildPlanName",
          title: "Build Plan",
          type: "string",
          gridWidth: 190,
          format: {
            type: "link",
            fieldId: "BuildPlanId",
            refName: "FSMBuildPlans",
          },
          aggregate: "count",
        },
        {
          field: "BPStatus",
          title: "BP Status",
          type: "string",
          gridWidth: 90,
          format: {
            type: "string",
            fieldId: "BPStatus",
          },
        },
        {
          field: "BPOName",
          title: "BP Owner",
          type: "string",
          gridWidth: 120,
          format: {
            type: "string",
            fieldId: "BPOId",
          },
        }
      );
    }
    this.COLUMNS.push(
      {
        title: "Sales Orders QTY",
        field: "SalesOrdersQTY",
        type: "number",
        format: {
          type: "number",
          customFormat: true,
          getCustomFormat: this.renderSOQTYCell,
        },
      },
      {
        title: "Sales Orders Amount",
        field: "SalesOrdersAmount",
        type: "number",
        format: {
          type: "currency",
        },
      },
      {
        field: "Number",
        title: "Number",
        type: "string",
        format: {
          type: "link",
          refName: "FSMWorkOrders",
          fieldId: "Id",
        },
      },
      {
        field: "TypeName",
        title: "Type",
        type: "string",
        format: {
          type: "string",
          fieldId: "TypeId",
        },
      },
      {
        field: "IsImportantStr",
        title: "Important",
        type: "string",
        gridWidth: 90,
        format: {
          type: "string",
          fieldId: "IsImportant",
        },
      }
    );

    if (this.isBPTab) {
      this.COLUMNS.push({
        field: "IsPrevailingWageStr",
        title: "PW",
        type: "string",
        gridWidth: 70,
        format: {
          type: "string",
          fieldId: "IsPrevailingWage",
        },
        aggregate: "count",
      });
    }

    this.COLUMNS.push(
      {
        field: "CategoryName",
        title: "Category",
        type: "string",
        minWidth: 180,
        format: {
          type: "string",
          fieldId: "CategoryId",
        },
      },
      {
        field: "Stage",
        title: "Stage",
        type: "string",
        gridWidth: 120,
        format: {
          type: "string",
          fieldId: "Stage",
        },
      },
      {
        field: "LastPublishedDispatchDate",
        title: "Last Published Dispatch",
        type: "date",
        gridWidth: 110,
        columnMenu: false,
        format: {
          type: "date",
        },
      },
      {
        field: "LastCrewLeadName",
        title: "Assigned To",
        type: "string",
        format: {
          type: "string",
          fieldId: "LastCrewLeadId",
        },
      },
      {
        field: "LastOnSite",
        title: "Last On Site",
        type: "date",
        columnMenu: false,
        format: {
          type: "datetime",
        },
      },
      {
        field: "SiteName",
        title: "Site",
        type: "string",
        format: {
          type: "link",
          refName: "FSMSites",
          fieldId: "SiteId",
        },
      },
      {
        field: "LastComment",
        title: "Last Comment",
        type: "string",
        columnMenu: false,
        gridWidth: 110,
        format: {
          type: "icon",
          iconClass: "k-icon k-font-icon k-i-file-txt",
          customFormat: true,
          getCustomFormat: this.renderTextIconCell,
        },
      },
      {
        field: "TotalHours",
        title: "Total Hrs",
        gridWidth: 110,
        type: "number",
        columnMenu: false,
        format: {
          type: "number",
        },
        aggregate: "sum",
      },
      {
        field: "Description",
        title: "Description",
        type: "string",
        gridWidth: 90,
        columnMenu: false,
        format: {
          type: "icon",
          iconClass: "k-icon k-font-icon k-i-file-txt",
          customFormat: true,
          getCustomFormat: this.renderTextIconCell,
        },
      },
      {
        field: "CompletedDate",
        title: "Completed",
        gridWidth: 110,
        type: "date",
        columnMenu: false,
        format: {
          type: "date",
        },
      },
      {
        field: "Status",
        title: "Status",
        type: "string",
        gridWidth: 90,
        format: {
          type: "string",
          fieldId: "Status",
        },
        values: [
          {
            Name: "Active",
            Id: "Active",
            FieldId: "Status",
            Selected: true,
          },
          {
            Name: "Closed",
            Id: "Closed",
            FieldId: "Status",
            Selected: true,
          },
        ],
      }
    );
  }

  render() {
    if (
      !this.isBPTab &&
      this.props.isActive &&
      this.bpId !== this.props.buildPlanId
    ) {
      this.bpId = this.props.buildPlanId;
    }

    if (
      (this.isBPTab && !this.props.buildPlanId) ||
      (!this.props.isActive && this.bpId !== this.props.buildPlanId)
    )
      return null;
    let defaultColumnValuesFilter:
      | {
          field: string;
          values: any[];
        }[]
      | undefined = undefined;
    if (this.props.defaultColumnValuesFilter) {
      defaultColumnValuesFilter = this.props.defaultColumnValuesFilter;
    } else if (this.setDefaultFilters) {
      defaultColumnValuesFilter = [
        {
          field: "Status",
          values: ["Active"],
        },
        {
          field: "Stage",
          values: ["Completed", "Scheduled", "Published"],
        },
      ];
    }
    return (
      <>
        <DashboardGrid
          key={this.isBPTab ? this.props.buildPlanId : "WorkOrdersReport"}
          ref={this.gridRef}
          isParentPage={!this.isBPTab}
          isActive={this.props.isActive}
          columnsSettings={this.COLUMNS}
          filters={!this.isBPTab ? this.FILTERS : undefined}
          getData={this.LoadGridData}
          onMarkRow={this.OnMark}
          getRowKey={this.GetRowKey}
          initRefresh={this.InitRefresh}
          onRowContextMenu={this.OnRowContextMenu}
          excelFileName={
            this.isBPTab
              ? "Build Plan Work Orders.xlsx"
              : "Work Orders Report.xlsx"
          }
          columnMenu={{ filters: false, columns: false }}
          toolbar={{
            isSingleRowToolbar: true,
            actionBtns: (
              <>
                <Button onClick={this.NewWO}>New WO</Button>
                <Button onClick={this.NewDispatch}>New Dispatch</Button>
              </>
            ),
          }}
          defaultColumnValuesFilter={defaultColumnValuesFilter}
          onChangeGridData={this.props.onChangeGridData}
        />
        <Popup
          offset={this.state.contextMenu?.position}
          show={!!this.state.contextMenu}
        >
          <Menu
            vertical={true}
            onSelect={this.OnSelectContextMenuItem}
            className={"k-context-menu"}
          >
            <MenuItem
              key={"updateComments"}
              data={"updateComments"}
              text="Update comments"
            ></MenuItem>
          </Menu>
        </Popup>
      </>
    );
  }

  OnRowContextMenu = (dataItem: simpleObject, e: any) => {
    const canUpdateComments = dataItem?.BPStatus === "Active";
    if (!canUpdateComments) return;

    const { clientX: left, clientY: top } = e;
    this.setState({
      contextMenu: {
        position: { left, top },
        woId: dataItem.Id,
        comments: dataItem.LastComment,
      },
    });
    document.addEventListener("click", this.CloseContextMenu);
  };

  CloseContextMenu = () => {
    this.setState({ contextMenu: undefined });
  };

  OnSelectContextMenuItem = (event: MenuSelectEvent) => {
    const action = event.item.data;
    const { contextMenu } = this.state;
    if (!contextMenu || !action) return;
    const { woId, comments } = contextMenu;
    if (action === "updateComments") {
      const textareaRef: any = React.createRef();
      ModalRef.showDialog({
        title: "Comments",
        width: 600,
        buttons: [
          {
            text: "Update",
            color: "primary",
            action: () => {
              let value = textareaRef.current?.value;

              if (value && value !== comments) {
                this.UpdateComments(woId, value);
                ModalRef.hideDialog();
              }
            },
          },
        ],
        children: (
          <TextArea
            ref={textareaRef}
            style={{ width: "100%" }}
            rows={7}
            required={true}
            defaultValue={comments}
          />
        ),
      });
    }
  };

  ShowValueInModal = (title: string, text: string) => {
    ModalRef.showDialog({
      title,
      children: <CopyTextComponent text={text} />,
      width: 600,
    });
  };

  renderTextIconCell = (dataItem: simpleObject, field: string) => {
    const value = dataItem[field];
    if (!value) return null;
    return (
      <div style={{ pointerEvents: "all" }}>
        <Tooltip
          anchorElement="target"
          position="left"
          style={{ maxWidth: "300px" }}
        >
          <Button
            title={value}
            fillMode={"flat"}
            style={{ display: "block", margin: "0 auto" }}
            iconClass={"k-icon k-font-icon k-i-file-txt" + " pointerEventsNone"}
            onClick={() =>
              this.ShowValueInModal(
                field === "Description" ? "Description" : "Last comment",
                value
              )
            }
          />
        </Tooltip>
      </div>
    );
  };

  UpdateComments = async (WorkOrderId: number, Comments: string) => {
    try {
      ModalRef.startProcessing();
      await api.script.woUpdateComments({
        WorkOrderId,
        Comments,
      });
    } catch (e: any) {
      showSomeError(e);
    } finally {
      this.Refresh();
      ModalRef.stopProcessing();
    }
  };

  renderSOQTYCell = (dataItem: simpleObject) => {
    if (!dataItem.SalesOrdersQTY) return null;
    return (
      <OpenCardLink
        dataAttr={dataItem.Id}
        text={dataItem.SalesOrdersQTY}
        onClick={this.OpenSalesOrders}
        refName="TK_ShowSalesOrderForWO"
      />
    );
  };

  LoadGridData = async (filters: simpleObject) => {
    const params: simpleObject = this.isBPTab
      ? { buildPlanId: this.props.buildPlanId }
      : {};

    const result = await this.GetSQLData({
      spName: "DB_BuildPlanWorkOrders",
      params: { ...params, ...filters },
    });
    const data = result[0];
    if (this.isBPTab) {
      for (let row of data) {
        row.IsPrevailingWageStr = row.IsPrevailingWage ? "Yes" : "No";
        row.IsImportantStr = row.IsImportant ? "Yes" : "No";
      }
    }
    return data;
  };

  OpenSalesOrders = (
    event: React.BaseSyntheticEvent,
    woId: number,
    refName: string
  ) => {
    RunScriptAsync(refName, { WOID: woId }).catch(showSomeError);
  };

  Refresh = () => {};

  InitRefresh = (refresh: any) => {
    this.Refresh = refresh;
  };

  GetRowKey = (dataItem: simpleObject) => dataItem.Id;

  OnMark = (dataItem: simpleObject | null) => {
    // if (!this.isBPTab) this.bpId = dataItem?.BuildPlanId
    this.woId = dataItem ? dataItem.Id : undefined;
  };

  NewWO = () => {
    CardManagement.NewWO(this.props.buildPlanId, this.Refresh);
  };

  NewDispatch = () => {
    CardManagement.OpenDispatchCard({
      newDispatch: true,
      buildPlanId: this.props.buildPlanId,
      woId: this.woId,
      afterSave: this.Refresh,
    });
  };
}

export default WorkOrders;
