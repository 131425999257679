import { ETCPerformAction } from "../interfaces";
import { IOpenSolicitTKRequest } from "../../../Components/Cards/interfaces";
import CardManagement from "../../../Components/Cards/CardManagement";
import { ModalRef } from "../../../Components/Common/Modal/Modal";
import { TextArea } from "@progress/kendo-react-inputs";
import { OpenRecord, RunScriptLocal } from "../../../helpers/runscripts";
import { Button } from "@progress/kendo-react-buttons";
import styles from "./tkTC.module.scss";
import React, { useCallback, useContext, useMemo } from "react";
import {
  isTKAdvancedReviewer,
  isTKCanCloneTCs,
  isTKCanCompleteTCs,
  isTKManager,
} from "../../../core/tools/roles";
import { performTCAction } from "../PerformTCActions";
import { PerformActionToSettings, reprocessTC } from "../helpers";
import { IntegrationWithDesktopContext } from "../../../helpers/settings";
import { SQL_DB_TK_Review_Response } from "../../../core/api/generated/conterra";
import { stopPropagationWrapper } from "../../../core/tools/Hooks";

const TCActions = (props: {
  tcId: number;
  data: SQL_DB_TK_Review_Response;
  refreshAfterAction: () => void;
  isCard?: boolean;
}) => {
  const { value: isIntegrationWithDesktop } = useContext(
    IntegrationWithDesktopContext
  );
  const { tcId, refreshAfterAction, data, isCard } = props;
  const availableActions = useMemo(() => {
    const { canComplete, canReturn, isPermitted } = data;
    const actions: ETCPerformAction[] = [];
    if (canComplete) actions.push(ETCPerformAction.Complete);
    if (canReturn) actions.push(ETCPerformAction.Reject);
    if (isPermitted) {
      const showTCActionsAsIsAdmin = isTKManager() || isTKCanCompleteTCs();
      if (!showTCActionsAsIsAdmin) {
        if (isTKAdvancedReviewer()) {
          actions.push(ETCPerformAction.Confirm);
        } else {
          actions.push(ETCPerformAction.NoChanges);
          actions.push(ETCPerformAction.ChangeTime);
        }
      }
      actions.push(ETCPerformAction.Reassign);
    }
    return actions;
  }, [data]);

  const { canReprocess, employeeID, date } = data;
  const solicitTKRequest = useCallback(() => {
    const params: IOpenSolicitTKRequest = {
      employeeId: employeeID,
      date,
      afterSave: refreshAfterAction,
    };
    CardManagement.OpenSolicitTKRequestCard(params);
  }, [employeeID, date, refreshAfterAction]);

  const handleAction = (actionName: ETCPerformAction) => {
    let commentRef: any;
    ModalRef.showDialog({
      title: PerformActionToSettings[actionName].title,
      buttons: [
        {
          text: "Cancel",
          action: () => {
            ModalRef.hideDialog();
          },
        },
        {
          text: "Ok",
          color: "primary",
          action: () => {
            let comment = commentRef.element.current.value;
            ModalRef.hideDialog();
            performTCAction({
              actionName,
              tcs: [tcId],
              comment,
              refreshAfterAction,
            });
          },
        },
      ],
      children: (
        <TextArea
          ref={(ref) => {
            commentRef = ref;
            if (ref && ref.focus) {
              ref?.focus();
            }
          }}
          rows={5}
          style={{ width: "100%" }}
        />
      ),
    });
  };

  return (
    <>
      <Button
        iconClass={"mdi mdi-email-outline"}
        title={"Solicit TK Request"}
        onClick={stopPropagationWrapper(solicitTKRequest)}
        fillMode={"flat"}
      ></Button>
      {canReprocess && (
        <Button
          onClick={stopPropagationWrapper(() =>
            reprocessTC(tcId, refreshAfterAction)
          )}
          className={styles.ListTCAction}
          title={"Reprocess Time Card"}
          fillMode={"flat"}
          iconClass={"mdi mdi-file-refresh-outline"}
        />
      )}
      {isIntegrationWithDesktop && isTKManager() && isTKCanCloneTCs() && (
        <Button
          className={styles.ListTCAction}
          iconClass={"mdi mdi-content-copy"}
          fillMode={"flat"}
          title={"Clone"}
          onClick={stopPropagationWrapper(() =>
            RunScriptLocal("TKTimeCards_Clone", { TCID: tcId })
          )}
        />
      )}
      {!isCard && isIntegrationWithDesktop && (
        <Button
          className={styles.ListTCAction}
          iconClass={"mdi mdi-file-outline"} // text-box-outline
          fillMode={"flat"}
          title={"Open Record"}
          onClick={stopPropagationWrapper(() =>
            OpenRecord("TKTimeCards", tcId)
          )}
        />
      )}
      {availableActions.map((action) => {
        const { iconClass, title } = PerformActionToSettings[action];
        return (
          <Button
            key={action}
            title={title}
            iconClass={iconClass}
            fillMode={"flat"}
            onClick={stopPropagationWrapper(() => handleAction(action))}
          />
        );
      })}
    </>
  );
};

export default TCActions;
