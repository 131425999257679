import { ModalRef } from "../../Components/Common/Modal/Modal";
import { IComboboxItem, simpleObject } from "../../helpers/interfaces";
import { UserSelect } from "./Toolbar/UserSelect";
import { RunScriptAsync } from "../../helpers/runscripts";
import { showSomeError } from "../../helpers/helpers";
import { ETCPerformAction } from "./interfaces";
import {
  isTKAdvancedReviewer,
  isTKCanCompleteTCs,
  isTKManager,
} from "../../core/tools/roles";
import React from "react";

export const performTCAction = async (props: {
  tcs: number[];
  actionName: ETCPerformAction;
  comment?: string;
  refreshAfterAction: () => void;
}) => {
  const { tcs, actionName, comment, refreshAfterAction } = props;
  if (!comment && actionName === ETCPerformAction.ChangeTime) {
    ModalRef.showDialog({
      title: "Action Error",
      text: "Comment is mandatory",
      type: "error",
    });
    return;
  }

  if (actionName === ETCPerformAction.Reassign) {
    let selectedUser: IComboboxItem | null = null;

    ModalRef.showDialog({
      title: "Select User for Assign",
      buttons: [
        {
          text: "Cancel",
          action: () => {
            ModalRef.hideDialog();
          },
        },
        {
          text: "Ok",
          color: "primary",
          action: () => {
            if (!selectedUser) return;
            ModalRef.hideDialog();
            confirmAction({
              actionName,
              ids: tcs,
              comment,
              refreshAfterAction,
              additionalParams: {
                ReassignToUserID: selectedUser.Id,
              },
            });
          },
        },
      ],
      children: (
        <div>
          <UserSelect
            onSelect={(item: IComboboxItem | null) => (selectedUser = item)}
          />
        </div>
      ),
    });
  } else {
    confirmAction({ actionName, ids: tcs, comment, refreshAfterAction });
  }
};

const confirmAction = (props: {
  actionName: ETCPerformAction;
  ids: number[];
  comment?: string;
  refreshAfterAction: () => void;
  additionalParams?: simpleObject;
}) => {
  const { actionName, ids, comment, refreshAfterAction, additionalParams } =
    props;
  ModalRef.showDialog({
    title: "Confirmation",
    text: `Confirm processing ${ids.length} Time Card${
      ids.length > 1 ? "s" : ""
    }.`,
    minWidth: 250,
    buttons: [
      {
        text: "Cancel",
        action: () => {
          ModalRef.hideDialog();
        },
      },
      {
        text: "Yes",
        color: "primary",
        action: () => {
          ModalRef.hideDialog();
          performAction({
            ActionName: actionName,
            TimeCardIDs: ids.join(","),
            Comment: comment,
            refreshAfterAction,
            additionalParams,
          });
        },
      },
    ],
  });
};

const performAction = async (props: {
  ActionName: ETCPerformAction;
  TimeCardIDs: string;
  Comment?: string;
  refreshAfterAction: () => void;
  additionalParams?: simpleObject;
}) => {
  const {
    ActionName,
    TimeCardIDs,
    Comment,
    refreshAfterAction,
    additionalParams,
  } = props;
  try {
    ModalRef.startProcessing();
    const error = await RunScriptAsync(
      "TK_COVER_PerformAction",
      {
        TimeCardIDs,
        Comment: Comment || ActionName,
        ActionName,
        ...(additionalParams || {}),
      },
      true
    );
    if (error) {
      showSomeError(error, "Perform Action Error", error);
    }
  } catch (e) {
    showSomeError(e);
  } finally {
    ModalRef.stopProcessing();
    refreshAfterAction();
  }
};

export const getAvailableTCActionsToPerform = () => {
  const showTCActionsAsIsAdmin = isTKManager() || isTKCanCompleteTCs();
  const actions: ETCPerformAction[] = [];
  if (showTCActionsAsIsAdmin) {
    actions.push(ETCPerformAction.Reject);
    actions.push(ETCPerformAction.Complete);
  } else if (isTKAdvancedReviewer()) {
    actions.push(ETCPerformAction.Confirm);
  } else {
    actions.push(ETCPerformAction.NoChanges);
    actions.push(ETCPerformAction.ChangeTime);
  }
  actions.push(ETCPerformAction.Reassign);
  return actions;
};
