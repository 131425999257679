import { getSQLData } from "../../helpers/queries";
import moment from "moment/moment";

export interface IMessage {
  Comment: string;
  Date: Date;
  Description: string;
  Employee: string;
  EmployeeId: string;
  RowNum: number;
  Event: string;
  Source: string | null;
}

class TKChatHistoryStore {
  private store: { [tcId: number]: IMessage[] } = {};

  async getTCChat(tcId: number, force?: boolean) {
    if (!force && this.store[tcId]) return this.store[tcId];
    const result = await getSQLData({
      spName: "TK_GetTimeCardHistory",
      params: { TCId: tcId, commentsOnly: true },
    });
    this.store[tcId] = result[0].map((item: IMessage) => ({
      ...item,
      Date: moment(item.Date).toDate(),
    }));
    return this.store[tcId];
  }

  resetTCChat(tcId: number) {
    delete this.store[tcId];
  }
}

export default new TKChatHistoryStore();
