import { IPage, pageId } from "./interfaces";
import Profile from "../Pages/Profile";
import EmployeesDashboard from "../Pages/EmployeesDashboard";
import BPDashboard from "../Pages/BPDashboard";
import ExternalLinks from "../Pages/ExternalLinks/ExternalLinks";
import StatusDashboard from "../Pages/StatusDashboard";
import DailyDashboard from "../Pages/DailyDashboard/DailyDashboard";
import Milestones from "../Pages/Milestones/Milestones";
import VIMDashboardPage from "../Pages/VIM/DashboardPage";
import VIMReview from "../Pages/VIM/ListPage";
import CLM from "../Pages/CLM/CLM";
import ExecutiveDailyDashboard from "../Pages/ExecutiveDailyDashboard/ExecutiveDailyDashboard";
import InventoryDashboard from "../Pages/InventoryDashboard";
import LiveMap from "../Pages/LiveMap/LiveMap";
import ActionsHistory from "../Pages/ActionsHistory";
import LaborDashboard from "../Pages/LaborDashboard";
import WIPDashboard from "../Pages/WIPDashboard";
import PLDashboard from "../Pages/PLDashboard/PLDashboard";
import BudgetDashboard from "../Pages/BudgetDashboard/BudgetDashboard";
import TKDashboard from "../Pages/TKDashboard";
import CrewLeadSummary from "../Pages/CrewLeadSummary";
import WorkOrdersReport from "../Components/BP/WorkOrders";
import CalendarComponent from "../Components/Frames/CalendarComponent";
import TKReviewNew from "../Pages/TKReview";
import Home from "../Pages/Home/Home";
import InboxDashboard from "../Pages/InboxDashboard";
import AGGridStatusDashboard from "../Pages/AGGrid/Dashboards/Status";

export const PAGES: { [key in pageId]: IPage } = {
  Profile: {
    component: Profile,
  },
  InboxDashboard: {
    component: InboxDashboard,
  },
  StatusDashboard: {
    component: StatusDashboard,
  },
  NewStatusDashboard: {
    component: AGGridStatusDashboard,
  },
  DailyDashboard: {
    component: DailyDashboard,
  },
  Milestones: {
    component: Milestones,
  },
  Home: {
    component: Home,
  },
  DB_Employees: {
    component: EmployeesDashboard,
  },
  TKAuditCover: {
    component: TKReviewNew,
  },
  Calendar: {
    component: CalendarComponent,
  },
  VIM_Invoices: {
    component: VIMDashboardPage,
  },
  VIM_Review: {
    component: VIMReview,
  },
  CLPM: {
    component: CLM,
  },
  CrewLeadDashboard: {
    component: CrewLeadSummary,
  },
  BPDashboard: {
    component: BPDashboard,
  },
  ExecutiveDailyDashboard: {
    component: ExecutiveDailyDashboard,
  },
  LaborDashboard: {
    component: LaborDashboard,
  },
  WorkOrdersReport: {
    component: WorkOrdersReport,
  },
  WIPDashboard: {
    component: WIPDashboard,
  },
  PLDashboard: {
    component: PLDashboard,
  },
  InventoryDashboard: {
    component: InventoryDashboard,
  },
  LiveMap: {
    component: LiveMap,
  },
  ActionsHistory: {
    component: ActionsHistory,
  },
  ExternalLinks: {
    component: ExternalLinks,
  },
  BudgetDashboard: {
    component: BudgetDashboard,
  },
  TKDashboard: {
    component: TKDashboard,
  },
};
