import React, { useCallback, useEffect, useState } from "react";
import {
  stopPropagationWrapper,
  useBooleanState,
} from "../../../core/tools/Hooks";
import { Popover } from "@progress/kendo-react-tooltip";
import styles from "./tkTC.module.scss";
import ChatHistoryStore from "../chatHistoryStore";
import {
  Chat,
  ChatMessageTemplateProps,
  Message,
} from "@progress/kendo-react-conversational-ui";
import LoaderComponent from "../../../Components/Common/Loader";
import { authProvider } from "../../../core/api/api";

export const QuickChatHistory = (props: {
  tcId: number;
  count: number;
  employeeCommentCount: number;
  reloadDependency: unknown;
}) => {
  const { tcId, count, employeeCommentCount, reloadDependency } = props;
  const isShownPopover = useBooleanState(false);
  const anchor = React.useRef<HTMLDivElement>(null);
  const isLoading = useBooleanState(false);
  const [messages, setMessages] = useState<Message[]>();

  useEffect(() => {
    if (isShownPopover.value) {
      getData(true);
    } else {
      setMessages(undefined);
      ChatHistoryStore.resetTCChat(tcId);
    }
  }, [reloadDependency]);

  useEffect(() => {
    if (isShownPopover.value && !messages) {
      getData();
    }
  }, [isShownPopover.value]);

  const getData = async (force?: boolean) => {
    isLoading.setTrue();
    const data = await ChatHistoryStore.getTCChat(tcId, force);
    setMessages(
      data.map((item) => {
        return {
          author: {
            id: item.EmployeeId,
            name: item.Employee,
            avatarAltText: item.Employee.substring(0, 1),
          },
          text: item.Comment,
          description: item.Description,
          timestamp: item.Date,
          isEmployeeComment: item.Event === "Employee Comment",
        };
      })
    );
    isLoading.setFalse();
  };

  useEffect(() => {
    if (isShownPopover.value) {
      document.addEventListener("click", isShownPopover.setFalse);
    } else {
      document.removeEventListener("click", isShownPopover.setFalse);
    }
    return () => {
      document.removeEventListener("click", isShownPopover.setFalse);
    };
  }, [isShownPopover.value]);

  const renderMessage = useCallback((props: ChatMessageTemplateProps) => {
    // @ts-ignore // description
    const { description, text, isEmployeeComment } = props.item;
    return (
      <div
        className={`k-chat-bubble ${
          isEmployeeComment ? styles.ChatEmployeeComment : ""
        }`}
      >
        {!!description && (
          <div className={styles.ChatDescription}>{description}</div>
        )}
        <div>{text}</div>
      </div>
    );
  }, []);

  const renderPopover = () => {
    return (
      <Popover
        show={isShownPopover.value}
        anchor={anchor.current}
        callout={true}
        collision={{
          vertical: "fit",
          horizontal: "flip",
        }}
        position={"right"}
        className={styles.Chat}
      >
        <div
          className={styles.ChatContainer}
          onClick={stopPropagationWrapper(() => {})}
        >
          {isLoading.value && <LoaderComponent />}
          {!!messages && (
            <Chat
              user={{
                id: authProvider.getUserId(),
              }}
              messages={messages}
              messageTemplate={renderMessage}
              showToolbar={false}
            />
          )}
        </div>
      </Popover>
    );
  };

  return (
    <>
      <div
        ref={anchor}
        className={`${styles.ChatBtn} ${
          isShownPopover.value ? styles.ChatBtnActive : ""
        } ${employeeCommentCount ? styles.ChatBtnRed : ""}`}
        onClick={stopPropagationWrapper(isShownPopover.toggle)}
      >
        <span className={`mdi mdi-chat ${styles.ChatIcon}`}></span>
        <span className={styles.ChatCount}>{count}</span>
      </div>
      {renderPopover()}
    </>
  );
};
