import { ColDef } from "ag-grid-enterprise";
import styles from "./aggrid.module.scss";
import { formatFinancial } from "../../helpers/helpers";
import moment from "moment";

export enum COMMON_COL_TYPES {
  currency = "currency",
  booleanYesNo = "booleanYesNo",
  dateFormatted = "dateFormatted",
  dateTimeFormatted = "dateTimeFormatted",
  float = "float",
  integer = "integer",
  percentage = "percentage",
}

export const COMMON_COL_TYPES_DESCRIPTION = {
  [COMMON_COL_TYPES.percentage]: {
    initialWidth: 110,
    minWidth: 90,
    filter: "agNumberColumnFilter",
    cellRenderer: (p: any) => {
      if (isNaN(p.value) || p.value === null) {
        return "";
      }

      return (
        <div className={styles.CenterCellContent}>{p.value.toFixed(2)}%</div>
      );
    },
  },
  [COMMON_COL_TYPES.float]: {
    initialWidth: 100,
    minWidth: 70,
    filter: "agNumberColumnFilter",
    aggFunc: "sum",
    cellRenderer: (p: any) => {
      if (isNaN(p.value) || p.value === null) {
        return "";
      }

      return (
        <div className={styles.CenterCellContent}>{p.value.toFixed(2)}</div>
      );
    },
  },
  [COMMON_COL_TYPES.integer]: {
    initialWidth: 100,
    minWidth: 70,
    filter: "agNumberColumnFilter",
    aggFunc: "sum",
    cellRenderer: (p: any) => {
      if (isNaN(p.value) || p.value === null) {
        return "";
      }

      return <div className={styles.CenterCellContent}>{p.value}</div>;
    },
  },
  [COMMON_COL_TYPES.currency]: {
    initialWidth: 150,
    minWidth: 100,
    filter: "agNumberColumnFilter",
    aggFunc: "sum",
    cellRenderer: (p: any) => {
      if (isNaN(p.value) || p.value === null) {
        return "";
      }

      return (
        <div className={styles.CurrencyCellContent}>
          <span>$</span>
          <span>{formatFinancial(p.value)}</span>
        </div>
      );
    },
    enableValue: true,
  },
  [COMMON_COL_TYPES.dateFormatted]: {
    enableValue: true,
    initialWidth: 130,
    minWidth: 90,
    cellRenderer: (p: any) => {
      if (!p.value) return "";
      return (
        <div className={styles.CenterCellContent}>
          {moment(p.value).format("L")}
        </div>
      );
    },
  },
  [COMMON_COL_TYPES.dateTimeFormatted]: {
    enableValue: true,
    initialWidth: 150,
    minWidth: 100,
    cellRenderer: (p: any) => {
      if (!p.value) return "";
      return (
        <div className={styles.CenterCellContent}>
          {moment(p.value).format("L LT")}
        </div>
      );
    },
  },
  [COMMON_COL_TYPES.booleanYesNo]: {
    initialWidth: 100,
    minWidth: 80,
    valueGetter: (p: any) => {
      const field = p.colDef.field;
      const value = p.data?.[field];
      if (value === null || value === undefined) return null;
      return p.data?.[field] ? "Yes" : "No";
    },
    cellRenderer: (p: any) => {
      if (p.value === null) return "";
      return (
        <div className={styles.YesNoCellContent}>
          {p.value === "Yes" ? (
            <span
              className={"mdi mdi-check"}
              style={{ color: "#0072c6", opacity: 0.8, fontSize: 18 }}
            ></span>
          ) : (
            <span
              className={"mdi mdi-minus"}
              style={{ color: "grey", opacity: 0.7, fontSize: 18 }}
            ></span>
          )}
        </div>
      );
    },
  },
} as Record<COMMON_COL_TYPES, ColDef>;
